import React, { useState, useEffect, FC } from 'react';
import { Button, Col, Row } from 'antd';
import classnames from 'classnames';
import Countdown, { zeroPad } from 'react-countdown';
import { getContract } from 'hook';
import { useWeb3React } from '@web3-react/core';
import { uniq } from 'lodash';
import BigNumber from 'bignumber.js';
import StatisticComponent from 'components/Statistic';
import { convertEToNumber, formatUnits, toNumber } from 'utils/helpers';
import { TOKEN_ADDRESS, TYPE_OF_ANT_DESIGN } from 'common/constant';
import showMessage from 'components/Message';
import TokenJSON from 'contracts/abiToken.json';
import StatisticRoundComponent from 'components/StatisticRound';
import SmokeCar from 'resources/images/smoke-car.svg';
import Car1 from 'resources/images/car.svg';
import Car2 from 'resources/images/car_2.svg';
import Icon1 from 'resources/images/icon-statistic-1.png';
import Icon2 from 'resources/images/icon-statistic-2.png';
import Icon3 from 'resources/images/icon-statistic-3.png';
import Icon4 from 'resources/images/icon-statistic-4.png';
import Icon1White from 'resources/images/icon-1-white.svg';
import Icon2White from 'resources/images/icon-2-white.svg';
import Icon3White from 'resources/images/icon-3-white.svg';
import Icon4White from 'resources/images/icon-4-white.svg';
import Icon5White from 'resources/images/icon-5-white.svg';

const rectangleColor1 = 'linear-gradient(194deg, #C55E3F 0%, #FF7D53 82.49%)';
const rectangleColor2 = 'linear-gradient(180deg, #CE2D71 0%, #FF3B97 100%)';
const rectangleColor3 = 'linear-gradient(180deg, #8237A9 0%, #AD49E2 100%)';
const rectangleColor4 = 'linear-gradient(180deg, #2D2FCE 0%, #3B3EFF 100%)';
const boxShadowColor1 =
  '10px 10px 27.3092px rgba(254, 98, 51, 0.34), inset 1.02771px -4.12191px 4.2481px #BC593C, inset -1.02771px 4.12191px 4.2481px #FF8256';
const boxShadowColor2 =
  '20px 20px 27.3092px -10px rgba(232, 52, 133, 0.3), 8.36103e-16px 13.6546px 27.3092px rgba(49, 11, 27, 0.24), inset -2.60121e-16px -4.2481px 4.2481px #C42A6C, inset 2.60121e-16px 4.2481px 4.2481px #FF3E9C';
const boxShadowColor3 =
  '20px 20px 27.3092px -10px rgba(152, 64, 198, 0.3), 8.36103e-16px 13.6546px 27.3092px rgba(31, 13, 40, 0.24), inset -2.60121e-16px -4.2481px 4.2481px #7C34A1, inset 2.60121e-16px 4.2481px 4.2481px #B44CEA';
const boxShadowColor4 =
  '20px 20px 27.3092px -10px rgba(51, 54, 229, 0.3), 8.36103e-16px 13.6546px 27.3092px rgba(11, 11, 49, 0.24), inset -2.60121e-16px -4.2481px 4.2481px #2A2CC4, inset 2.60121e-16px 4.2481px 4.2481px #3E40FF';
BigNumber.config({
  EXPONENTIAL_AT: 100,
});

const convertHexToDecimal = (value: any) => {
  if (!value) {
    return '0';
  }

  const newValue = new BigNumber(value.toString())
    .dividedBy(Math.pow(10, 18))
    .toString();

  return newValue;
};

const sumTwoNumber = (a: number | string | null, b: number | string | null) => {
  return new BigNumber(a || 0).plus(b || 0).toString();
};

const minusTwoNumber = (
  a: number | string | null,
  b: number | string | null
) => {
  return new BigNumber(a || 0).minus(b || 0).toString();
};

const SummaryRoundComponent: FC<{
  account: null | undefined | string;
  data: any;
}> = ({ account, data }) => {
  const [totalValue, setTotalValue] = useState({}) as any;

  useEffect(() => {
    const newTotalValue = Object.values(data).reduce(
      (acc: any, cur: any) => ({
        allocationAmount: sumTwoNumber(
          acc.allocationAmount,
          cur.allocationAmount
        ),
        claimedAmount: sumTwoNumber(acc.claimedAmount, cur.claimedAmount),
        availableAmount: sumTwoNumber(acc.availableAmount, cur.availableAmount),
        claimableAmount: sumTwoNumber(acc.claimableAmount, cur.claimableAmount),
      }),
      {
        allocationAmount: 0,
        claimedAmount: 0,
        availableAmount: 0,
        claimableAmount: 0,
      }
    );
    setTotalValue(newTotalValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, data]);

  return (
    <Row
      gutter={[
        0,
        {
          xs: 58,
          sm: 48,
        },
      ]}
    >
      {/* <Col span={24}>
        <h4 className='title'>Vulture Peak Vesting Portal</h4>
      </Col> */}
      <Col className='statistic-wrapper' span={24}>
        <img className='smoke-car-svg' src={SmokeCar} alt='' />

        <Row gutter={[30, 16]}>
          <Col xs={24} lg={12} xl={6}>
            <StatisticComponent
              percent={totalValue.allocationAmount}
              isConnected={!!account}
              title='Allocation'
              iconUrl={Icon1White}
              className='statistic'
              rectangleColor={rectangleColor1}
              boxShadow={boxShadowColor1}
            />
          </Col>
          <Col xs={24} lg={12} xl={6}>
            <StatisticComponent
              percent={totalValue.availableAmount}
              isConnected={!!account}
              title='Available'
              iconUrl={Icon2White}
              className='statistic'
              rectangleColor={rectangleColor2}
              boxShadow={boxShadowColor2}
            />
          </Col>
          <Col xs={24} lg={12} xl={6}>
            <StatisticComponent
              percent={totalValue.claimedAmount}
              isConnected={!!account}
              title='Claimed '
              iconUrl={Icon3White}
              className='statistic'
              rectangleColor={rectangleColor3}
              boxShadow={boxShadowColor3}
            />
          </Col>

          <Col xs={24} lg={12} xl={6}>
            <StatisticComponent
              percent={totalValue.claimableAmount}
              isConnected={!!account}
              title='Claimable'
              iconUrl={Icon4White}
              className='statistic'
              rectangleColor={rectangleColor4}
              boxShadow={boxShadowColor4}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const RoundComponent: FC<{
  updateAllData: any;
  title?: string;
  isCountdown?: null | undefined | number;
  address: string;
  abi?: any;
  communityRound?: boolean;
  index?: number;
  setListRoundVisible?: any;
  keyItem?: number;
}> = ({
  updateAllData,
  title = '',
  isCountdown = false,
  address,
  abi,
  communityRound,
  index,
  setListRoundVisible,
  keyItem,
}) => {
  const { account, library } = useWeb3React();

  const [roundData, setRoundData] = useState({
    allocationAmount: null,
    claimedAmount: null,
    claimableAmount: null,
    availableAmount: null,
  });
  const [isShowBtnClaim, setIsShowBtnClaim] = useState(false);

  const [timeCountdown, setTimeCountdown] = useState(0);
  const [isBtnClaim, setBtnClaim] = useState(false);

  const rendererCountdown = ({ days, hours, minutes, seconds }: any) => {
    return (
      <ul className='countdown'>
        <li className='time'>
          <span className={classnames('value', { highlight: days })}>
            {zeroPad(days)}D
          </span>
        </li>
        :
        <li className='time'>
          <span className={classnames('value', { highlight: days || hours })}>
            {zeroPad(hours)}H
          </span>
        </li>
        :
        <li className='time'>
          <span
            className={classnames('value', {
              highlight: days || hours || minutes,
            })}
          >
            {zeroPad(minutes)}M
          </span>
        </li>
        :
        <li className='time'>
          <span
            className={classnames('value', {
              highlight: days || hours || minutes || seconds,
            })}
          >
            {zeroPad(seconds)}S
          </span>
        </li>
      </ul>
    );
  };

  /**
   * get Balance
   * @returns balance
   */
  const getBalance = async (
    library: any,
    address: string,
    tokenAddress: string
  ) => {
    const tokenInst = getContract(tokenAddress, TokenJSON.output.abi, library);
    if (address) {
      const balance = await tokenInst.balanceOf(address);
      const decimals = await tokenInst.decimals();

      return {
        balance: convertEToNumber(formatUnits(balance, 'wei'), decimals),
      };
    } else {
      return {
        balance: 0,
      };
    }
  };

  const claimRound = async () => {
    setIsShowBtnClaim(true);
    const { balance } = await getBalance(library, address, TOKEN_ADDRESS);

    if (balance < (roundData?.claimableAmount || 0)) {
      showMessage(
        TYPE_OF_ANT_DESIGN.ERROR,
        'The claiming contract runs out of PGK, please contact the Peguin Karts administrators.'
      );

      setIsShowBtnClaim(false);
      return;
    }

    try {
      const contract = getContract(address, abi, library, account as string);
      const res = await contract.unlock(account);
      const receipt = await library.waitForTransaction(res.hash);
      if (receipt.status) {
        getAllData();
      }
      setIsShowBtnClaim(false);
    } catch (err) {
      setIsShowBtnClaim(false);
    }
  };

  const handleComplete = () => {
    setTimeCountdown(0);
    setBtnClaim(true);
  };

  const getAllData = async () => {
    try {
      const contract = getContract(address, abi, library, account as string);
      const lockedAmount = await contract.lockedAmountOf(account);
      const data = await Promise.all([
        contract.lockedAmountOf(account),
        contract.releasedAmountOf(account),
      ]);
      let claimableAmount = 0;
      try {
        claimableAmount = (await lockedAmount.gt(0))
          ? await contract.canUnlockAmountOf(account)
          : 0;
      } catch (ex) {
        claimableAmount = 0;
      }
      const [allocationAmount, claimedAmount] = data;
      const availableAmount = minusTwoNumber(
        convertHexToDecimal(allocationAmount),
        convertHexToDecimal(claimedAmount)
      );

      const newData = {
        allocationAmount: convertHexToDecimal(allocationAmount),
        claimedAmount: convertHexToDecimal(claimedAmount),
        claimableAmount: convertHexToDecimal(claimableAmount),
        availableAmount: availableAmount,
      };

      if (!toNumber(newData.allocationAmount)) {
        setListRoundVisible((prevState: any) =>
          prevState.filter((item: any) => item !== keyItem)
        );
      } else {
        setListRoundVisible((prevState: any) => uniq([...prevState, keyItem]));
      }

      setRoundData((prevState: any) => ({
        ...prevState,
        ...newData,
      }));

      updateAllData({ ...newData, index });
    } catch (err) {
      console.log('err=', err);
    }
  };

  useEffect(() => {
    if (account) {
      getAllData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, abi, address, index, library]);

  useEffect(() => {
    if (account && isCountdown) {
      const contract = getContract(address, abi, library, account as string);
      const getCountdownData = async () => {
        try {
          let time;
          const currentTime = Math.floor(new Date().getTime() / 1000);
          if (communityRound) {
            time = await contract._timestamps(0);
            setTimeCountdown(time.toNumber() * 1000);
          } else {
            time = await contract._unlockTime();
            setTimeCountdown(time.toNumber() * 1000);
          }
          if (currentTime >= time.toNumber()) {
            setBtnClaim(true);
          } else {
            setBtnClaim(false);
          }
        } catch (err) {
          console.log(err);
        }
      };
      getCountdownData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isCountdown, address, abi, library]);

  return (
    <div
      className={`round ${
        !!timeCountdown && !isBtnClaim && 'current-coutdown'
      }`}
    >
      <h4 className='round__title'>
        <div className='text'>{title}</div>
      </h4>
      <Row gutter={[{ xs: 45, md: 48 }, 34]}>
        <Col xs={24} md={12}>
          <StatisticRoundComponent
            isConnected={!!account}
            title='Claimed Amount'
            iconUrl={Icon3White}
            percent={roundData.claimedAmount}
            className='statistic-card'
          />
        </Col>
        <Col xs={24} md={12}>
          <StatisticRoundComponent
            isConnected={!!account}
            title='Claimable Amount'
            iconUrl={Icon4White}
            percent={roundData.claimableAmount}
            className='statistic-card'
          />
        </Col>
        <Col xs={24} md={12}>
          <StatisticRoundComponent
            isConnected={!!account}
            title='Allocation Amount'
            iconUrl={Icon1White}
            percent={roundData.allocationAmount}
            className='statistic-card'
          />
        </Col>
        <Col xs={24} md={12}>
          <StatisticRoundComponent
            isConnected={!!account}
            title='Available Amount'
            iconUrl={Icon2White}
            percent={roundData.availableAmount}
            className='statistic-card'
          />
        </Col>
      </Row>
      <div className='round__bottom'>
        {isBtnClaim && (
          <Button
            loading={isShowBtnClaim}
            type='primary'
            onClick={claimRound}
            className='button-claim'
            disabled={!toNumber(roundData.claimableAmount) || !account}
          >
            Claim
          </Button>
        )}
        {!!timeCountdown && !isBtnClaim && (
          <div className='countdown-wrapper'>
            <Countdown
              date={timeCountdown}
              renderer={rendererCountdown}
              onComplete={handleComplete}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { SummaryRoundComponent, RoundComponent };
