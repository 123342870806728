import * as React from 'react';

function IconMenu(props: any) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.66671 10.6667C5.93033 10.6667 5.33337 11.2637 5.33337 12.0001C5.33337 12.7365 5.93033 13.3334 6.66671 13.3334H25.3334C26.0698 13.3334 26.6667 12.7365 26.6667 12.0001C26.6667 11.2637 26.0698 10.6667 25.3334 10.6667H6.66671Z'
        fill='white'
      />
      <path
        d='M6.66671 18.6667C5.93033 18.6667 5.33337 19.2637 5.33337 20.0001C5.33337 20.7365 5.93033 21.3334 6.66671 21.3334H25.3334C26.0698 21.3334 26.6667 20.7365 26.6667 20.0001C26.6667 19.2637 26.0698 18.6667 25.3334 18.6667H6.66671Z'
        fill='white'
      />
    </svg>
  );
}

export default IconMenu;
