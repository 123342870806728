
import TokenLocker from 'contracts/TokenLocker_metadata.json'
const list = [
  {
    key: 0,
    address: process.env.REACT_APP_ANGEL_ROUND as string,
    title: 'Angel Round',
    abi: TokenLocker.output.abi,
    isCountdown: true,
  },
  {
    key: 1,
    address: process.env.REACT_APP_SEED_ROUND as string,
    title: 'Seed Round',
    abi: TokenLocker.output.abi,
    isCountdown: true,
  },
  {
    key: 2,
    address: process.env.REACT_APP_PRIVATE_ROUND as string,
    title: 'Private Round',
    abi: TokenLocker.output.abi,
    isCountdown: true,
  },
  {
    key: 3,
    address: process.env.REACT_APP_STRATEGIC_ROUND as string,
    title: 'Strategic Round',
    abi: TokenLocker.output.abi,
    isCountdown: true,
  },
  {
    key: 4,
    address: process.env.REACT_APP_IDO_LAUCHPAD_ROUND as string,
    title: 'IDO/Lauchpad Round',
    abi: TokenLocker.output.abi,
    isCountdown: true,

  },
  {
    key: 5,
    address: process.env.REACT_APP_ADVISORS_ROUND as string,
    title: 'Advisors Round',
    abi: TokenLocker.output.abi,
    isCountdown: true,

  },
  {
    key: 6,
    address: process.env.REACT_APP_AIRDROP as string,
    title: 'Airdrop Round',
    abi: TokenLocker.output.abi,
    isCountdown: true,

  },
  {
    key: 7,
    address: process.env.REACT_APP_PARAGEN_ROUND as string,
    title: 'Paragen Investors Round',
    abi: TokenLocker.output.abi,
    isCountdown: true,

  },
];

export default list;