import React, { useState, FC } from 'react';
import { Button, Col, Layout, Row, Space, Switch } from 'antd';

import { useWeb3React } from '@web3-react/core';
import { RoundComponent, SummaryRoundComponent } from 'components/Round';
import listRound from './listRound';
import Cash from 'resources/svg/Icon_cash.svg';
import backgroundVideo from 'resources/videos/background.mp4';
import Smoke from 'resources/images/smoke.png';

const HomePage: FC<any> = ({ connectButtonRef }) => {
  const { account } = useWeb3React();

  const [isInvested, setIsInvested] = useState(false);
  const [listRoundVisible, setListRoundVisible] = useState(listRound.map(({ key }) => key)) as any;

  const [summaryData, setSummaryData] = useState({}) as any;

  const handleChange = (value: boolean) => {
    setIsInvested(value);
  };

  const toggleConnectWalletModal = () => {
    connectButtonRef.current.triggerClickConnect();
  };

  const updateAllData = (data: any) => {
    const { index, allocationAmount, claimedAmount, availableAmount, claimableAmount } = data;
    setSummaryData((prevState: any) => ({
      ...prevState,
      [index]: {
        allocationAmount: allocationAmount || 0,
        claimedAmount: claimedAmount || 0,
        availableAmount: availableAmount || 0,
        claimableAmount: claimableAmount || 0,
      },
    }));
  };

  return (
    <Layout className="home">
      {/* {!account && (
        <div className='banner banner-guest'>
          <div className='container'>
            <h1 className='title'>Vulture Peak Vesting Portal</h1>

            <Button type='primary' onClick={toggleConnectWalletModal}>
              <img src={Cash} alt='' />
              Connect Wallet
            </Button>
          </div>
        </div>
      )} */}
      {/* {account && ( */}
      <>
        {/* <div className="banner">
          <div className="container">
            <Row>
              <Col xs={24}>
                <SummaryRoundComponent account={account} data={summaryData} />
              </Col>
            </Row>
          </div>
        </div> */}
        <div className="section section-details">
          <div className="container">
            <h4 className="title">Vesting Round Details</h4>
            <Space size={12} className="switch">
              <Switch checked={isInvested} onChange={handleChange} disabled={!account} />
              <span className="switch__label">Invested only</span>
            </Space>
            <Row
              gutter={[
                30,
                {
                  xs: 70,
                  sm: 70,
                  xl: 32,
                },
              ]}
              className="list-round"
            >
              {listRound.map((item: any, index: number) => (
                <Col xs={24} xl={12} key={index} hidden={isInvested && !listRoundVisible.includes(item.key)}>
                  <RoundComponent
                    title={item.title}
                    isCountdown={item.isCountdown}
                    updateAllData={updateAllData}
                    index={index}
                    setListRoundVisible={setListRoundVisible}
                    keyItem={item.key}
                    {...item}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </>
      )
    </Layout>
  );
};
export default HomePage;
