import React, { useState, useEffect, useImperativeHandle, FC, forwardRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Drawer, Layout, Modal, Popover, Space, Spin } from 'antd';
import { useWeb3React } from '@web3-react/core';
import { LoadingOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';

import Logo from 'resources/images/PENGUIN _KARTS_LOGO_1.png';
import { getContract, useConnectWallet, useWindowResize } from 'hook';
import { convertEToNumber, formatNetworkAddress, formatUnits, storeAddress, toNumber } from 'utils/helpers';
import IconMenu from 'resources/svg/IconMenu';
import IconClose from 'resources/svg/IconClose';
import { socialLinks } from 'pages/layout/socials';
import { APP_NETWORKS_SUPPORT, DECIMAL_SCALE, NETWORK_CHAIN_ID, TOKEN_ADDRESS } from 'common/constant';
import { walletConnect } from 'connectors';
import IconWalletConnect from 'resources/svg/wallet_connect.svg';
import IconMetamask from 'resources/svg/metamask.svg';
import IconRight from 'resources/svg/icon_right.svg';
import Cash from 'resources/svg/Icon_cash.svg';
import logout from 'resources/svg/logout.svg';
import classNames from 'classnames';
import { FTM_CHAIN_ID } from 'utils/networks';
import TokenPGKAbi from 'contracts/token.json';
import BigNumber from 'bignumber.js';
const { Header } = Layout;
BigNumber.config({
  EXPONENTIAL_AT: 100,
});
const formatNumber = (value?: string | null) => {
  if (!toNumber(value)) {
    return '0.00';
  } else if (toNumber(value) < Math.pow(10, -2)) {
    return `< ${Math.pow(10, -2)}`;
  }

  return new BigNumber(value || 0)
    .toFixed(DECIMAL_SCALE)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
const HeaderCommon: FC<any> = forwardRef(({ connectButtonRef }, ref) => {
  const { connectInjected, connectWalletConnect } = useConnectWallet();
  const { error, chainId, account, active, deactivate, library } = useWeb3React();
  const { width } = useWindowResize();
  const location = useLocation();
  const [balancePGK, setBalancePGK] = useState('0.00');
  const address = localStorage.getItem('address');
  const isWalletConnect = localStorage.getItem('walletconnect');
  const IconLoading = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [isConnected, setIsConnected] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isVisibleConnectModal, setIsVisibleConnectModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  const menu = [
    {
      title: 'Home',
      path: 'https://vulturepeak.io/',
      comingSoon: false,
      highlight: {},
    },
    {
      title: 'Launchpads',
      path: 'https://launchpad.vulturepeak.io/',
      comingSoon: false,
      highlight: {},
    },
  ];

  const onConnectMetamask = () => {
    setIsConnecting(true);
  };

  const onConnectMetamaskSuccess = () => {
    setIsConnecting(false);
  };

  const handleConnectMetamask = () => {
    if (chainId && ![Number(FTM_CHAIN_ID)].includes(chainId)) {
      setIsModalVisible(true);
      return;
    }
    if (window.ethereum) {
      connectInjected(null, onConnectMetamask, onConnectMetamaskSuccess);
    } else {
      alert('Please install metamask');
    }
    setVisible(false);
  };

  const handleConnectWallet = () => {
    connectWalletConnect();
  };
  const getBalance = async (library: any, address: string, tokenAddress: string) => {
    const tokenInst = getContract(tokenAddress, TokenPGKAbi.output.abi, library);
    if (address) {
      const balance = await tokenInst.balanceOf(address);
      const decimals = await tokenInst.decimals();
      const symbol = await tokenInst.symbol();
      return {
        balance: convertEToNumber(formatUnits(balance, 'wei'), decimals),
        symbol,
      };
    } else {
      return {
        balance: 0,
      };
    }
  };

  useEffect(() => {
    if (account) {
      getBalance(library, account, TOKEN_ADDRESS)
        .then((result) => {
          const { balance, symbol } = result;
          setBalancePGK(balance?.toString());
        })
        .catch((error) => {
          setBalancePGK('0.00');
          console.log(error);
        });
    }
  }, [account, chainId]);

  const setupNetwork = async () => {
    const provider = window.ethereum;
    const networkInfo = APP_NETWORKS_SUPPORT[Number(FTM_CHAIN_ID)];

    if (provider) {
      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${(+FTM_CHAIN_ID).toString(16)}` }],
        });
      } catch (error: any) {
        //Reject metamask
        console.log(error);
        if (error.code === 4902) {
          console.log(networkInfo);
          try {
            await provider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  ...(networkInfo.details || {}),
                },
              ],
            });
          } catch (addError) {
            return false;
          }
        } else {
          return false;
        }
        if (error.code === 4001) {
          return;
        }
      }
    }
  };
  const toggleConnectWalletModal = () => {
    setIsVisibleDrawer(false);
    setIsVisibleConnectModal((prevState: boolean) => !prevState);
  };

  const handleDisconnectMetamask = () => {
    localStorage.removeItem('address');
    localStorage.removeItem('walletconnect');

    if (active) {
      deactivate();
    }
    if (isWalletConnect) {
      walletConnect.close();
      walletConnect.activate();
    }
  };

  const handleVisibleChange = () => {
    setVisible((prevState: boolean) => !prevState);
  };

  const handleVisibleDrawer = () => {
    setIsVisibleDrawer((prevState: boolean) => !prevState);
  };

  useImperativeHandle(connectButtonRef, () => ({
    triggerClickConnect: () => {
      toggleConnectWalletModal();
    },
  }));

  useEffect(() => {
    setIsVisibleDrawer(false);

    return () => {
      setIsVisibleDrawer(false);
    };
  }, [location.pathname]);

  useEffect(() => {
    setIsConnected(!error && !!chainId);
    console.log(chainId);
    if (chainId) {
      if ([Number(FTM_CHAIN_ID)].includes(chainId)) {
        setIsModalVisible(false);
      } else {
        setIsModalVisible(true);
      }
    }
  }, [error, chainId]);

  useEffect(() => {
    if (address && !active) {
      if (isWalletConnect) {
        setTimeout(() => connectWalletConnect(), 700);
      } else {
        setTimeout(() => connectInjected(), 700);
      }
    }
  }, [address, isWalletConnect, active, connectWalletConnect, connectInjected]);

  useEffect(() => {
    if (isWalletConnect) {
      walletConnect.activate();
      walletConnect?.on('Web3ReactDeactivate', (data: any) => {
        // TODO: active wallet on reload
        localStorage.removeItem('walletconnect');
        localStorage.removeItem('address');

        if (active) {
          deactivate();
        }
        walletConnect.close();
      });
    }
  }, [isWalletConnect, active, deactivate]);
  useEffect(() => {
    if (active && account) {
      storeAddress(account);
      setIsVisibleConnectModal(false);
      onConnectMetamaskSuccess();
    } else if (!address) {
      setIsVisibleConnectModal(true);
    }
  }, [account, active, address]);

  useEffect(() => {
    if (width < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  return (
    <Header className="header" id="header">
      <div className="container">
        <div className="header__left">
          <img src={Logo} alt="Vulture Peak" width={208} height={77} />
        </div>
        <div className="header__right">
          {!isMobile && (
            <>
              {/* {!isConnected && (
                <Button type="primary" onClick={toggleConnectWalletModal}>
                  <img src={Cash} alt="" />
                  Connect Wallet
                </Button>
              )} */}
              {isConnected && (
                <div className="group-btn">
                  <span style={{ marginRight: '1rem', color: 'white' }}>{formatNumber(balancePGK)} PGK</span>
                  <Popover
                    content={
                      <Button type="primary" onClick={handleDisconnectMetamask} className="button-disconnect">
                        <img src={logout} alt="" /> Disconnect
                      </Button>
                    }
                    trigger="click"
                    visible={visible}
                    onVisibleChange={handleVisibleChange}
                    placement="bottom"
                    getPopupContainer={() => document.getElementById('header') as HTMLElement}
                  >
                    <Button className="button-connected">{account && formatNetworkAddress(account)}</Button>
                  </Popover>
                </div>
              )}
            </>
          )}
          {isMobile && <Button icon={<IconMenu />} className="button-menu" onClick={handleVisibleDrawer} />}
        </div>
      </div>
      <Drawer
        title={null}
        placement="right"
        width="100%"
        onClose={handleVisibleDrawer}
        visible={isVisibleDrawer}
        closable={false}
        className="drawer"
      >
        <div className="header">
          <div className="header__right">
            <Button icon={<IconClose />} className="button-menu" onClick={handleVisibleDrawer} />
          </div>
        </div>
        {/* <div className='body'>
          <ul>
            {menu.map((item, index) => (
              <li className={classNames('nav-item')} key={index}>
                <a href={item.path}>{item.title}</a>
              </li>
            ))}
          </ul>
        </div> */}
        <div className="drawer__footer">
          {/* <ul className='social'>
            {socialLinks.map(({ name, icon, link }: any) => (
              <li className='social__icon' key={name}>
                <a href={link} target='_blank' rel='noopener noreferrer'>
                  <img src={icon} alt={name} />
                </a>
              </li>
            ))}
          </ul> */}

          <div className="btn-wrapper">
            {!isConnected && (
              <Button type="primary" onClick={toggleConnectWalletModal}>
                <img src={Cash} alt="" /> Connect Wallet
              </Button>
            )}
            {isConnected && (
              <>
                <Button className="button-connected">{account && formatNetworkAddress(account)}</Button>
                <Button type="primary" onClick={handleDisconnectMetamask} className="button-disconnect">
                  <img src={logout} alt="" /> Disconnect
                </Button>
              </>
            )}
          </div>
        </div>
      </Drawer>
      <Modal
        title={null}
        visible={isModalVisible}
        footer={null}
        width={544}
        closable={false}
        className="wrong-network-modal"
      >
        <Spin indicator={IconLoading} />
        <h2 className="title">Wrong Network</h2>
        <div className="description">Please change network on your wallet</div>
        <Button className="switch-network" onClick={setupNetwork}>
          Switch Network
        </Button>
      </Modal>

      <Modal
        title={null}
        visible={isVisibleConnectModal}
        footer={null}
        width={410}
        closable={false}
        // onCancel={toggleConnectWalletModal}
        className="connect-wallet-modal"
      >
        <h2 className="title">$PGK Distribution portal</h2>
        <div className="description">
          <img src={Logo} alt="logo"></img>
          <p className="description__first">Claim Your Vesting</p>
          <p className="description__second">
            Let us detect if you have any unclaimed PGK in public, private or seed round by connecting to your wallet
            first.
          </p>
        </div>
        <Space direction="vertical" size="middle">
          {window.ethereum ? (
            <Button onClick={handleConnectMetamask} disabled={isConnecting}>
              <img src={IconMetamask} alt="Metamask" className="icon__left" />
              <span>Metamask</span>
              {/* <img src={IconRight} alt='right' className='icon__right' /> */}
            </Button>
          ) : (
            <Button
              onClick={() => {
                window.open('https://metamask.io/');
              }}
            >
              Install Metamask
            </Button>
          )}
          <Button onClick={handleConnectWallet} disabled={isConnecting}>
            <img src={IconWalletConnect} alt="Wallet Connect" className="icon__left" />
            <span>WalletConnect</span>
            {/* <img src={IconRight} alt='right' className='icon__right' /> */}
          </Button>
        </Space>
      </Modal>
    </Header>
  );
});
export default withTranslation()(HeaderCommon);
