import React, { FC } from 'react';
import { Progress, Skeleton, Tooltip } from 'antd';
import BigNumber from 'bignumber.js';

import { DECIMAL_SCALE } from 'common/constant';
import { toNumber } from 'utils/helpers';
BigNumber.config({
  EXPONENTIAL_AT: 100,
});

const formatNumber = (value?: string | null) => {
  if (!toNumber(value)) {
    return '0.00';
  } else if (toNumber(value) < Math.pow(10, -2)) {
    return `< ${Math.pow(10, -2)}`;
  }

  return new BigNumber(value || 0)
    .toFixed(DECIMAL_SCALE)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const StatisticComponent: FC<{
  isConnected: boolean;
  iconUrl?: any;
  title: string;
  className?: string;
  percent?: null | string;
  children?: any;
  rectangleColor?: any;
  boxShadow?: any;
}> = ({
  isConnected = false,
  iconUrl,
  title,
  percent,
  className = '',
  children,
  rectangleColor,
  boxShadow,
}) => {
  const isNull = (value: any) => {
    return value === null;
  };

  return (
    <div className={`${className}`}>
      {/* {iconUrl && (
        <div className='statistic__left'>
          <img src={iconUrl} alt='' />
        </div>
      )} */}
      <div className='statistic__right'>
        <div className='statistic__content'>
          <div
            className='statistic__rectangle'
            style={{ background: rectangleColor, boxShadow: boxShadow }}
          >
            <img src={iconUrl} alt='' width='50px' />
          </div>
          <div className='statistic__title'>
            {title} <br /> <p>amount</p>
          </div>
          {children || (
            <>
              {!isConnected && (
                <Progress
                  percent={30}
                  steps={17}
                  showInfo={false}
                  strokeColor='#08f2f1'
                  trailColor='#192b6d'
                />
              )}
              {isConnected && (
                <div className='statistic__value'>
                  {!isNull(percent) ? (
                    <Tooltip title={formatNumber(percent)}>
                      {formatNumber(percent)}
                    </Tooltip>
                  ) : (
                    <Skeleton.Input style={{ width: 60 }} active size='small' />
                  )}{' '}
                  <p>PGK</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatisticComponent;
