import { randomRPCTesnet } from 'utils/helpers';
import { FTM_CHAIN_ID } from 'utils/networks';

export const POLLING_INTERVAL = 10000;

export const TOKEN_ADDRESS = process.env.REACT_APP_TOKEN_ADDRESS as string;

export const DECIMAL_SCALE = 2;

export interface NetworkInfo {
  name: string;
  id?: string | undefined;
  currency?: string;
  [k: string]: any;
}
export const TYPE_OF_ANT_DESIGN = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  IMG_DONE: 'done',
};

const LIST_BSC_TESTNET = [
  'https://data-seed-prebsc-1-s1.binance.org:8545/',
  'https://data-seed-prebsc-2-s1.binance.org:8545/',
  'https://data-seed-prebsc-1-s2.binance.org:8545/',
  'https://data-seed-prebsc-2-s2.binance.org:8545/',
  'https://data-seed-prebsc-1-s3.binance.org:8545/',
  'https://data-seed-prebsc-2-s3.binance.org:8545/',
];

export const LIST_NETWORK_RPC_TESTNET: any = {
  97: randomRPCTesnet(LIST_BSC_TESTNET),
  80001: 'https://rpc-mumbai.maticvigil.com/',
  4: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  4002: 'https://rpc.testnet.fantom.network/',
};

export const LIST_NETWORK_RPC_MAINNET: any = {
  56: 'https://bsc-dataseed.binance.org/',
  137: 'https://rpc-mainnet.maticvigil.com/',
  1: 'https://mainnet.infura.io/v3/50f6131e95134c0fba1a009b561a31d9',
  250: 'https://rpc.ftm.tools/',
};

export const NETWORK_CHAIN_ID = {
  BSC: 56,
  FTM: 250,
  FTM_TESTNET: 4002,
};

export const METAMASK_DEEPLINK = 'https://metamask.io/download';
export const BRIDGE_URL = 'https://pancakeswap.bridge.walletconnect.org';
export const NETWORK_URL_BSC = 'https://bsc-dataseed.binance.org/';

export const APP_NETWORKS_SUPPORT: { [key: number]: NetworkInfo } = {
  [FTM_CHAIN_ID]: {
    name: 'FANTOM OPERA',
    id: FTM_CHAIN_ID,
    currency: 'FTM',
    networkName: 'FANTOM_OPERA',
    details: {
      chainId: `0x${(+FTM_CHAIN_ID).toString(16)}`,
      chainName: 'FANTOM_OPERA',
      nativeCurrency: {
        name: 'FTM',
        symbol: 'FTM',
        decimals: 18,
      },
      rpcUrls: ['https://rpc.ftm.tools/'],
      blockExplorerUrls: ['https://ftmscan.com/'],
    },
  },
};
